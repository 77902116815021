import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Fab, Stack } from '@mui/material';
import ScrollBox from './ScrollBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { FormBodyType, PaymentActionStateType } from '../Forms/commonForms';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { useMessageContext, IMessageContext } from '@contexts/MessageContext';
import type { IUserChatAction } from '@interfaces/Conversation';
import { useUserContext } from '@contexts/user';
import { ValidatorFunctions } from '@common/Validators';
import { ScrollDirectionType } from './MessageFetchStateManager';
import { AppsMenuExternalState } from './AppsMenu/AppsMenu';
import { IAppsMenuState } from '@contexts/FooterContext';
import eventBus from '@common/eventBus';
import { appsMenuButtonTools } from './AppsMenu/AppsMenuButton';
import useThrottleImmediate from '@common/hooks/useThrottleImmediate';

export const ScrollHandlerEventsConfig = {
  CHANNEL_ID : 'SCROLL_HANDLER',
  EVENTS : {
    FORCE_SCROLL_BOTTOM: "FORCE_SCROLL_BOTTOM"
  }
}

type TScrollHandlerProps = {
  action: IUserChatAction;
  children: ReactNode;
  isPrivate: boolean;
};

const THRESHOLD_LOAD_MORE = 500;
const THRESHOLD_GO_TO_BOTTOM = 50;
const THRESHOLD_MIN_SCROLLTOP = 1;

/**
 * Whenever we detect content mutation following handler fired
 * @returns
 */
const handleContentChanges = (messageContext: IMessageContext) => {
  const parameters = messageContext.bodyRef.current;

  if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
    return;
  }

  const scrollState = messageContext.scrollStateRef.current;
  const lockedToBottom = scrollState.lockedToBottom;
  const direction = scrollState.direction;
  const scrollHeight = parameters.scrollHeight;
  const scrollTop = parameters.scrollTop;

  if (scrollHeight > scrollState.scrollHeight) {
    // content size increased
    if (scrollTop === 0 || scrollTop !== scrollState.scrollTop || scrollHeight !== scrollState.scrollHeight) {
      // there were scroll movement or scroll is at top
      if (lockedToBottom === false || direction !== ScrollDirectionType.Down) {
        // scroll is not locked to bottom
        const newScrollTop = scrollState.scrollTop + scrollHeight - scrollState.scrollHeight;

        // on mobile device, negative overwrite observed
        if (newScrollTop <= THRESHOLD_MIN_SCROLLTOP) {
          parameters.scrollTop = THRESHOLD_MIN_SCROLLTOP;
        } else {
          parameters.scrollTop = newScrollTop;
        }
      }
    }
  }
};

/**
 * loading more message trigger logic lives here
 */
const handleLoadMore = (messageContext: IMessageContext) => {
  const parameters = messageContext.bodyRef.current;

  if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
    return;
  }

  const scrollState = messageContext.scrollStateRef.current;
  const scrollTop = parameters.scrollTop;
  const direction = scrollState.direction;

  if (scrollTop * 10 <= THRESHOLD_LOAD_MORE) {
    // scrollbar disabled scenario
    // skeletion loader stuck at top scenario
    messageContext.loadMoreMessages();
  } else if (direction === ScrollDirectionType.Up && scrollTop < THRESHOLD_LOAD_MORE) {
    // user scrolling up scenario
    messageContext.loadMoreMessages();
  }
};

/**
 * We track old scroll state with following handler
 * @returns
 */
const handleScrollStateCache = (messageContext: IMessageContext) => {
  const parameters = messageContext.bodyRef.current;

  if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
    return;
  }

  const scrollState = messageContext.scrollStateRef.current;
  const scrollTop = parameters.scrollTop;
  const scrollHeight = parameters.scrollHeight;
  const clientHeight = parameters.clientHeight;

  if (scrollState.clientHeight === clientHeight && scrollState.scrollHeight === scrollHeight) {
    // view window size not changed and content size not changed
    if (scrollState.scrollTop < scrollTop) {
      scrollState.direction = ScrollDirectionType.Down;
    } else if (scrollState.scrollTop > scrollTop) {
      scrollState.direction = ScrollDirectionType.Up;
    }
  }

  scrollState.scrollTop = scrollTop;
  scrollState.scrollHeight = scrollHeight;
  scrollState.clientHeight = clientHeight;
};

/**
 * if scroll locked to bottom, it should stay that way when new data comes in
 */
const handleScrollToBottom = (messageContext: IMessageContext) => {
  const parameters = messageContext.bodyRef.current;

  if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
    return;
  }

  const scrollState = messageContext.scrollStateRef.current;
  const scrollTop = parameters.scrollTop;
  const clientHeight = parameters.clientHeight;
  const scrollHeight = parameters.scrollHeight;
  const direction = scrollState.direction;
  const lockedToBottom = scrollState.lockedToBottom;

  if (scrollTop + clientHeight + 1 < scrollHeight && lockedToBottom && direction === ScrollDirectionType.Down) {
    /**
     * if following condition matches then scroll to bottom
     * - scroll is not at near to (1px tolerance)
     * - scroll is locked to bottom
     * - last scroll direction was to DOWN
     */
    messageContext.moveScrollToBottom();
  }
};

const log = (messageContext: IMessageContext, identifier: string) => {
  const parameters = messageContext.bodyRef.current;

  if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
    return;
  }

  const wr = (val: number) => {
    return val.toFixed(1).toString().padStart(10, ' ');
  };

  const scrollState = messageContext.scrollStateRef.current;
  const scrollTop = parameters.scrollTop;
  const scrollHeight = parameters.scrollHeight;
  const clientHeight = parameters.clientHeight;

  console.debug(
    `ScrollHandler ${identifier.padStart(20, ' ')} ${wr(scrollTop)}${wr(clientHeight)}${wr(scrollHeight)}${wr(
      scrollState.scrollTop
    )}${wr(scrollState.clientHeight)}${wr(scrollState.scrollHeight)}${scrollState.direction.padStart(10, ' ')} ${
      scrollState.lockedToBottom
    }`
  );
};


const setAppsSearchWasCancelled = (isOperator, bool) => {
  if(!isOperator) {
    AppsMenuExternalState.dispatchEvent(AppsMenuExternalState.EVENTS.SET_WAS_SEARCH_CANCELLED, { value: bool })
  }
}

const setAppsSearchMode = (currMorphType, isOperator, chatHasActiveAction, bool) => {
  if(isOperator || chatHasActiveAction || currMorphType !== MorphType.None) return;

  AppsMenuExternalState.dispatchEvent(AppsMenuExternalState.EVENTS.SET_SEARCH_MODE, { value: bool })
}

const ScrollHandler = ({ action, children, isPrivate }: TScrollHandlerProps) => {
  const errandContext = useErrandContext();
  const messageContext = useMessageContext();
  const { isOperator } = useUserContext();
  const [isAppMenuOpen, setIsAppMenuOpen] = useState(false);
  const [isSearchView, setIsSearchView] = useState(false);
  const [wasSearchCancelled, setWasSearchCancelled] = useState(false);
  const chatHasActiveAction = useMemo(() => {
    const [errandActionId, errandActionActionId] = [errandContext.errand?.action?._id, errandContext.errand?.action?.action?._id];
    return ValidatorFunctions.isNotUndefinedNorNull(errandActionId) || ValidatorFunctions.isNotUndefinedNorNull(errandActionActionId);
  }, [errandContext.errand?.action?._id, errandContext.errand?.action?.action?._id]);
  // const [isSearchModeOn, setIsSearchMode] = useState(false);

  const windowDimensions = useWindowDimensions();

  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  const fieldAttribute = action?.action?.fieldAttribute;

  const isAppsMenuButtonShown = useMemo(() => !appsMenuButtonTools.getIsHidden(errandContext?.morphType, errandContext?.errand, isOperator), [errandContext?.morphType, errandContext?.errand, isOperator]);

  const paddingBottom = useMemo(() => {
    return errandContext?.morphType
      ? errandContext?.morphType === MorphType.Errand
        ? '61px'
        : errandContext?.morphType === MorphType.Contacts
        ? '37px'
        : errandContext?.morphType === MorphType.LoanProductPriceTable
        ? '260px'
        : errandContext?.morphType === MorphType.PointsTable
        ? '400px'
        : errandContext?.morphType === MorphType.Attachment
        ? '98px'
        : errandContext?.morphType === MorphType.MessageOptions
        ? '98px'
        : errandContext?.morphType === MorphType.UserPromptsMenu ||
          errandContext?.morphType === MorphType.VideoListMenu ||
          errandContext?.morphType === MorphType.CreditRepairDisputeAccountType
        ? '130px'
        : errandContext?.morphType === MorphType.SelectMultiple
        ? '150px'
        : errandContext?.morphType === MorphType.UserSuggestions
        ? '60px'
        : errandContext?.morphType === MorphType.Time
        ? '180px'
        : [MorphType.CalendarMonth, MorphType.DOB].includes(errandContext?.morphType)
        ? '260px'
        : // Only add padding bottom when payment is not in the preview state (when only the floating card appears)
        errandContext?.morphType === MorphType.Payment &&
          errandContext?.paymentActionState !== PaymentActionStateType.Preview
        ? '200px'
        : errandContext?.morphType === MorphType.PrivateChat && !isPrivate
        ? '250px'
        : fieldAttribute?.description === 'DROPDOWN'
        ? '37px'
        : '23px'
      : '23px';
  }, [errandContext?.morphType, errandContext?.paymentActionState, fieldAttribute?.description, isPrivate]);

  const combinedRef = useCallback((el) => {
    if (el) {
      messageContext.bodyRef.current = el;
      errandContext.bodyRef.current = el;
    }
  }, [errandContext.bodyRef, messageContext.bodyRef]);

  /**
   * Scroll down button show/hide logic lives here
   */
  const handleScrollDownButtonShowLogic = useCallback(() => {
    const parameters = messageContext.bodyRef.current;

    if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
      return;
    }

    const scrollTop = parameters.scrollTop;
    const scrollHeight = parameters.scrollHeight;
    const clientHeight = parameters.clientHeight;
    const scrollState = messageContext.scrollStateRef.current;
    const direction = scrollState.direction;

    if (direction === ScrollDirectionType.Up) {
      scrollState.lockedToBottom = false;
    }

    // hide SCROLL_DOWN_BUTTON event
    // OR we are at the very start of the chat
    if (scrollTop + clientHeight + THRESHOLD_GO_TO_BOTTOM > scrollHeight) {
      setShowScrollToBottom(false);
      // start of the chat
      // and if NOT in searchView
      if(isSearchView === false) {
        setAppsSearchMode(errandContext?.morphType, isOperator, chatHasActiveAction, false);
      }
      // reset scroll was canceled IF menu is not currently shown
      if(!isAppMenuOpen) setAppsSearchWasCancelled(isOperator, false);

      // default process
      if (direction === ScrollDirectionType.Down) {
        scrollState.lockedToBottom = true;
      }
    } 
    // show SCROLL_DOWN_BUTTON event
    // OR we scrolled up a bit
    else {
      if(isAppMenuOpen === true) {
        setShowScrollToBottom(false);
        if(isSearchView === false) {
          setAppsSearchMode(errandContext?.morphType, isOperator, chatHasActiveAction, false);
        }
      } else {
        // if apps menu button is shown, no need to show the scroll to bottom piece that is rendered on the right side bottom area.
        // instead the scroll to bottom is managed through the Apps Menu button
        setShowScrollToBottom(isAppsMenuButtonShown ? false : true);
        setAppsSearchMode(errandContext?.morphType, isOperator, chatHasActiveAction, wasSearchCancelled ? false : true);
      }
    }
  }, [
    messageContext.bodyRef, 
    messageContext.scrollStateRef, 
    isAppMenuOpen, 
    isSearchView, 
    wasSearchCancelled, 
    isAppsMenuButtonShown, 
    isOperator, 
    chatHasActiveAction,
    errandContext?.morphType
  ]);

  /**
   * handle whole windows view dimention  changes
   */
  useWindowDimensions(
    useCallback(() => {
      handleScrollStateCache(messageContext);
      handleScrollToBottom(messageContext);
      handleLoadMore(messageContext);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  useEffect(() => {
    const subscriberID = 'ScrollHandler';
    const _handler = (updatedState: IAppsMenuState) => {
      setIsAppMenuOpen(updatedState?.isOpened ?? false);
      setIsSearchView(updatedState?.searchView ?? false);
      setWasSearchCancelled(updatedState?.searchWasCancelled ?? false);
    };
    AppsMenuExternalState.subscribe(subscriberID, _handler)
    return () => {
      AppsMenuExternalState.unsubscribe(subscriberID)
    }
  }, [setIsAppMenuOpen, setIsSearchView])

  const handleScrollDownButtonShowLogicThrottled = useThrottleImmediate(handleScrollDownButtonShowLogic, 300);

  useEffect(() => {
    handleScrollDownButtonShowLogicThrottled();
  }, [isAppMenuOpen, handleScrollDownButtonShowLogicThrottled]) // react to open/close of the app menu component.

  /**
   * Following useEffect will track component life cycle
   * we create and define observer for conversation body
   */
  useEffect(() => {
    handleScrollStateCache(messageContext);
    handleScrollToBottom(messageContext);

    const scrollerElement = messageContext.bodyRef.current;

    const observer: any = {
      /**
       * Messages DOM element mutation observer (whenever message context gets updated following observer fired)
       */
      mutation: new MutationObserver(() => {
        // On mutation, update the scrollHeight
        if (scrollerElement) {
          log(messageContext, 'mutation');
          handleContentChanges(messageContext);
          handleScrollStateCache(messageContext);
          handleScrollToBottom(messageContext);
          handleLoadMore(messageContext);
        }
      }),
      /**
       * Messages box, resize observer (whenever clientHeight gets updated following observer fired)
       */
      resize: new ResizeObserver(() => {
        if (scrollerElement) {
          log(messageContext, 'resize');
          handleScrollToBottom(messageContext);
        }
      }),
    };

    if (scrollerElement) {
      observer.mutation.observe(scrollerElement, { childList: true, subtree: true });
      observer.resize.observe(scrollerElement);
    }

    // Cleanup observer on component unmount
    return () => {
      observer.mutation.disconnect();
      observer.resize.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // event processor for events sent via eventBus FROM other external components.
    const handleEvent = (eventData) => {
      if (!eventData.detail) return;
  
      const { eventId, data } = eventData?.detail;
      // If we received the FORCE_SCROLL_BOTTOM signal from another component
      if(eventId === ScrollHandlerEventsConfig.EVENTS.FORCE_SCROLL_BOTTOM) {
        // scroll to bottom
        messageContext?.moveScrollToBottom();
      }
    }
  
    eventBus.on(ScrollHandlerEventsConfig.CHANNEL_ID, handleEvent);
    return () => {
        eventBus.remove(ScrollHandlerEventsConfig.CHANNEL_ID, handleEvent);
    }
  }, [messageContext?.moveScrollToBottom])

  return (
    <>
      <ScrollBox
        flexDirection="column"
        sx={{
          height: 'fit-content',
          minHeight: '100%',
          //66 is the height of the footer by default and 56 is the height of the conv title. Greater heights are used to account for when the footer is morphed or
          // for smaller window sizes like mobile. The minHeight will always take priority so it will never be too small. We just need to ensure it doesn't extend past the bottom of
          // the screen/conv body
          maxHeight: `calc(100vh - 122px - ${paddingBottom} - ${
            windowDimensions.isDesktop ? (isOperator ? '500px' : '56px') : '166px'
          })`,
          width: 'calc(100% - 1px)',
          padding: windowDimensions.isDesktop ? '0px 38px 23px 39px' : '0px 28px 23px 29px',
          margin: '0',
          overflowY: errandContext.formBody === FormBodyType.CreateSignatureMobile ? 'hidden' : 'auto',
          overflowX: 'hidden',
          overscrollBehavior: 'none',
          WebkitOverflowScrolling: 'touch',
          transform: 'none',
          // scrollbarWidth: 'thin',
          paddingBottom: paddingBottom,
          // Scrollbar styling applied globally in index.css
          // '&::-webkit-scrollbar': {
          //   width: '0.4em',
          // },
          // '&::-webkit-scrollbar-track': {
          //   boxShadow: 'inset 0 0 6px var(--shadow000)',
          //   webkitBoxShadow: 'inset 0 0 6px var(--shadow000)',
          // },
          // '&::-webkit-scrollbar-thumb': {
          //   backgroundColor: 'var(--shadow110)',
          //   outline: '1px solid slategrey',
          //   borderRadius: '0.2em',
          // },
          '&>div': {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
          },
        }}
        ref={combinedRef}
        onScroll={() => {
          handleScrollStateCache(messageContext);
          handleScrollDownButtonShowLogic();
          handleLoadMore(messageContext);
          handleScrollToBottom(messageContext);
        }}
      >
        {children}
      </ScrollBox>
      {showScrollToBottom && (
        <Stack justifyContent="center" alignItems="flex-end">
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => messageContext.moveScrollToBottom()}
            sx={{
              backgroundColor: 'var(--orange000)!important',
              position: 'absolute',
              mr: '16px',
              borderRadius: '100%',
              width: '40px',
              height: '40px',
              boxShadow: 'none',
              bottom: '8px',
              zIndex: '1000',
            }}
          >
            <KeyboardArrowDownIcon sx={{ color: 'var(--gray000)', width: '1.55em', height: '1.55em' }} />
          </Fab>
        </Stack>
      )}
    </>
  );
};

export default ScrollHandler;
