import React, { useMemo, Suspense } from 'react';
import { DeletedMessageContentFallback, PrivateMessageContentFallback } from './Fallbacks';
import { MessageFallbackSelector } from './Fallbacks/MessageFallbackSelector';
import { MessageTypeSelector } from '@components/MessageTypeSelector';
import DeletedMessageContent from '@components/MessageContent/DeletedMessageContent';
import PrivateMessageContent from '@components/MessageContent/PrivateMessageContent';
import ErrorBoundary from '@components/ErrorBoundary';
import { useErrandContext } from '@contexts/ErrandContext';
import { useMessageSingleContext } from '@contexts/message';

const MessageContentSkeleton = React.memo(() => {
  const { errand } = useErrandContext();

  const { message, index, isDeletedMessageContent, isPrivateMessageContent } = useMessageSingleContext();

  const componentTypeProps = useMemo(() => {
    // prop definitions for error boundary reports
    const ErrandProp = {
      errand,
    };

    const MessageProp = {
      message,
    };

    const ErrandMessageProps = {
      errand,
      message,
    };

    const IndexMessageProps = {
      index,
      message,
    };

    const ErrandIndexMessageProps = {
      errand,
      index,
      message,
    };

    return {
      ErrandProp,
      MessageProp,
      ErrandMessageProps,
      IndexMessageProps,
      ErrandIndexMessageProps,
    };
  }, [message, errand, index]);

  const messageComponent = useMemo(
    () =>
      isDeletedMessageContent ? (
        <DeletedMessageContent {...componentTypeProps.MessageProp} />
      ) : isPrivateMessageContent ? (
        <PrivateMessageContent {...componentTypeProps.ErrandIndexMessageProps} />
      ) : (
        // all other msg types
        <MessageTypeSelector componentTypeProps={componentTypeProps} message={message} />
      ),
    [componentTypeProps, isDeletedMessageContent, isPrivateMessageContent, message]
  );

  const fbComponent = useMemo(
    () =>
      isDeletedMessageContent ? (
        <DeletedMessageContentFallback message={message} />
      ) : isPrivateMessageContent ? (
        <PrivateMessageContentFallback message={message} />
      ) : (
        // all other message types
        <MessageFallbackSelector message={message} />
      ),
    [isDeletedMessageContent, isPrivateMessageContent, message]
  );

  if (!message) return <></>;

  return (
    <ErrorBoundary debug={'./src/Components/Skeletons/MessageContentSkeleton.tsx'}>
      <Suspense fallback={fbComponent}>{messageComponent}</Suspense>
    </ErrorBoundary>
  );
});

export default MessageContentSkeleton;
