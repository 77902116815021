import React from 'react';
import Styles from '@styles/MorphPointsTable.module.css';
import { useAppSelector, useAppDispatch } from '@common/hooks/reduxHooks';
import { PointsTableView, selectPointsTableView, setPointsTableView } from '@slices/pointsSlice';
import { AngelPointsIcon, PointsHistoryIcon } from '@assets/Icons';
import { useTranslation } from 'react-i18next';

const PointsTableViewButtons = () => {
  const pointsTableView = useAppSelector(selectPointsTableView);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div className={Styles.viewButtonContainer}>
      <button
        className={`${Styles.viewButton} ${pointsTableView === PointsTableView.History && Styles.selectedTableView}`}
        onClick={() => {
          dispatch(setPointsTableView(PointsTableView.History));
        }}
      >
        <PointsHistoryIcon className={Styles.viewButtonIcon} />
        <p className={Styles.viewButtonText}>{t('summary')}</p>
      </button>
      <button
        className={`${Styles.viewButton} ${
          pointsTableView === PointsTableView.EarnedPoints && Styles.selectedTableView
        }`}
        style={{ borderTopRightRadius: `${window.innerWidth < 900 ? '0px' : '5px'}` }}
        onClick={() => {
          dispatch(setPointsTableView(PointsTableView.EarnedPoints));
        }}
      >
        <AngelPointsIcon className={Styles.viewButtonIcon} />
        <p className={Styles.viewButtonText}>{t('earn')}</p>
      </button>
    </div>
  );
};

export default PointsTableViewButtons;
