import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useRootContext } from '@contexts/RootContext';
import Styles from '@styles/RopeDrawer.module.css';
import eventBus from '../Common/eventBus';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import useDocumentVisibility from '@common/hooks/useDocumentVisibility';
import { useAppContext } from '@contexts/AppContext';
import { useUserContext } from '@contexts/user';
const RopeWithBump = process.env.REACT_APP_MORGAN_CDN + '/Images/rope-with-bump.png';
const RopeMobileDrawer = process.env.REACT_APP_MORGAN_CDN + '/Images/RopeMobileDrawer.png';

const getThreshold = () => Math.ceil(0.1 * document.documentElement.clientWidth);
// taken from RopeDrawer.module.css
// a bit more than 0.3 seconds
const ANIMATION_DURATION = 320;

// Permitted delay if nothing happens (close/open action) for user
const USER_NO_ACTION_DELAY = 1500;

type State = {
  isPullingRope: boolean,
  isInvisible: boolean,
  isDrawerOpen: boolean,
  drawerPosition: number,
  isAnimated: boolean,
  pullRopeImgAnimated: boolean,
  orientation: 0 | 1
}

const INIT_CLOSED_POS_VAL = (() => {
  let res = Math.ceil(0.6 * document.documentElement.clientWidth);
  res = res > 300 ? 300 : res;
  res = res < 200 ? 200 : res;
  return res;
})()

const RopeDrawer = (props) => {
  const rootContext = useRootContext();
  const appContext = useAppContext();
  const isWidget = rootContext.isWidget;
  const debouncedWindowDimensions = useWindowDimensions(); // debounced
  const [state, setState] = useState<State>({
    isPullingRope: false,
    isInvisible: true,
    isDrawerOpen: false,
    drawerPosition: INIT_CLOSED_POS_VAL,
    isAnimated: false,
    pullRopeImgAnimated: true,
    orientation: 0
  });
  const wasDraggedRef = useRef(false);
  const childWrapperRef = useRef(null);
  const pullAreaRef = useRef(null);
  // used for marking when the open/close swipe animation is finished or in progress.
  const animationCompletedRef = useRef(true);
  const animationTimeoutRef = useRef(null);
  const startTouchRef = useRef({ x: null, y: null });
  const automaticControllerTmRef = useRef(null);
  const invisibleTmRef = useRef(null);

  const clearAutomaticController = useCallback(() => {
    clearTimeout(automaticControllerTmRef.current);
    automaticControllerTmRef.current = null;
  }, [])

  const getClosedDrawerPosValue = useCallback((innerWidth = null) => {
    innerWidth =
      innerWidth === null
        ? document.documentElement.clientWidth
        : innerWidth;
    let res = Math.ceil(0.6 * innerWidth);
    res = res > 300 ? 300 : res;
    res = res < 200 ? 200 : res;
    return res;
  }, []);

  const closeDrawer = useCallback((e, force = false) => {
    if (e && e?.target !== undefined
      && e?.currentTarget !== undefined
      && e?.target !== e?.currentTarget
      && !force) return e?.stopPropagation();
    // only when its opened
    if (animationCompletedRef.current === true) {
      updateLocalState({
        isDrawerOpen: false,
        isPullingRope: false,
        isAnimated: true,
        drawerPosition: getClosedDrawerPosValue(),
        pullRopeImgAnimated: true
      });
      handleAnimationTracking();
      clearTimeout(invisibleTmRef.current);
      invisibleTmRef.current = setTimeout(() => {
        updateLocalState({
          isInvisible: true
        });
      }, ANIMATION_DURATION);
    }
    e && e?.stopPropagation && e?.stopPropagation();
    clearAutomaticController();
  }, [getClosedDrawerPosValue])


  // clear timeouts on unmount
  useEffect(() => {
    return () => {
      clearTimeout(animationTimeoutRef.current);
      clearTimeout(invisibleTmRef.current);
      clearAutomaticController();
    }
  }, [])

  // takes care of proper ropeDrawer positioning after resizing
  useEffect(() => {
    const updateDrawerPosOnResize = () => {
      const drawerPosBuffer = state.drawerPosition;
      // update drawer pos
      updateLocalState({
        // if open, leave opened.
        // if closed, leave closed.
        drawerPosition: drawerPosBuffer === 0 ? 0 : getClosedDrawerPosValue(),
        orientation: window.innerWidth > window.innerHeight ? 1 : 0
      })

      // queue as macro task
      setTimeout(() => {
        if (drawerPosBuffer === 0)
          openDrawer(null, true);
        else
          closeDrawer(null, true);
      }, 0);

    }

    updateDrawerPosOnResize();
  }, [
    debouncedWindowDimensions.innerHeight,
    debouncedWindowDimensions.innerWidth,
    getClosedDrawerPosValue
  ]);

  useEffect(() => {
    if (isMobileOrTablet() === false) return;

    const preventScroll = (e) => {
      e.preventDefault();
    }

    if (state.isPullingRope === true) {
      document.addEventListener('touchmove', preventScroll, { passive: false });
    } else {
      document.removeEventListener('touchmove', preventScroll);
    }

    return () => {
      document.removeEventListener('touchmove', preventScroll);
    }
  }, [state.isPullingRope])

  const checkClickOrTouchNearSendButton = useCallback((e) => {
    // on desktops it does not matter
    if (isMobileOrTablet() === false) {
      return false;
    }
    // check all vals for undefined or null
    e = e?.nativeEvent ?? e;
    const windowHeight = window?.visualViewport?.height ?? window.innerHeight; // window.innerHeight serves as a fallback if visualViewport is undefined
    const convoFooterTop = rootContext?.conversationFooterRef?.current.getBoundingClientRect()?.top;
    const fallbackTopValue = (windowHeight) - Math.ceil((windowHeight * 13) / 100);

    // get convoFooter top value, if its 0 (edge case when condition page is opened.)
    if (convoFooterTop === 0)
      return false; // not a click near send button because footer is not shown at all.

    let yBoundary = convoFooterTop ?? fallbackTopValue; // falback to 13% if conversationFooterRef is null/undefined
    let yCoord = null;
    // handle when e is passed Y coordinate
    if (typeof e === 'number') {
      yCoord = e;
    }
    // handle clicks
    if (e && (e.type === 'click' || e.type === 'mousedown' || e.type === 'mouseup')) {
      yCoord = e.pageY ?? e.clientY ?? null;
    }
    // handle touchstart touches
    if (e && e.type === 'touchstart') {
      yCoord = e?.touches[0]?.pageY ?? e?.touches[0]?.clientY ?? null;
    }
    // handle touchend touches
    if (e && e.type === 'touchend') {
      yCoord = e?.changedTouches[0]?.pageY ?? e?.changedTouches[0]?.clientY;
    }

    if (!yCoord) {
      console.warn('Unable to extract yCoord from touch/click!');
      return false;
    }
    if (yCoord >= yBoundary) {
      return true
    }

    // if neither checks worked, 
    // return false as the event happened not near send btn OR has some undefined OR null vals.
    return false;
  }, []);

  // Handlers & Helpers
  const checkStartClickOrTouchAllowed = useCallback((e) => {
    if ((!rootContext.allConsentsGiven) && props.operatorData === undefined) {
      rootContext.handleShakingConsent();
      return false;
    }
    if (checkClickOrTouchNearSendButton(e) === true) {
      return false;
    }
    if (appContext.showReloadButton) return false;

    return true;
  }, [props.operatorData, checkClickOrTouchNearSendButton, rootContext.handleShakingConsent, rootContext.allConsentsGiven, rootContext.clickedDisabledFeatures])


  const openDrawer = useCallback((e, force = false) => {
    if (appContext.showReloadButton) {
      return;
    }
    if (checkStartClickOrTouchAllowed(e) === false && state.drawerPosition === getClosedDrawerPosValue() && !force) {
      return;
    }
    // clear on-going invisible timeout.
    clearTimeout(invisibleTmRef.current);
    updateLocalState({
      isInvisible: false,
      isDrawerOpen: true,
      isPullingRope: false,
      isAnimated: true,
      drawerPosition: 0,
      pullRopeImgAnimated: true
    });
    handleAnimationTracking();
    clearAutomaticController();
  }, [checkStartClickOrTouchAllowed, getClosedDrawerPosValue, state.drawerPosition, appContext.showReloadButton])


  const processOpenCloseDrawer = useCallback((e) => {
    if (wasDraggedRef.current === false) {
      openDrawer(e);
    }
    else {
      if (state.drawerPosition <= getClosedDrawerPosValue() - getThreshold()) {
        openDrawer(e);
      } else {
        closeDrawer(e, true);
      }
    }
    wasDraggedRef.current = false;
  }, [openDrawer, closeDrawer, getClosedDrawerPosValue, state.drawerPosition])

  const processAutomaticController = useCallback((e) => {
    clearAutomaticController();
    automaticControllerTmRef.current = setTimeout(() => {
      closeDrawer(e, true);
    }, USER_NO_ACTION_DELAY)
  }, [closeDrawer])

  const handleAnimationTracking = useCallback(() => {
    // set animation in progress
    animationCompletedRef.current = false;
    animationTimeoutRef.current = setTimeout(() => {
      // set animation completed
      animationCompletedRef.current = true;
    }, ANIMATION_DURATION)
  }, []);

  const stopPropagation = useCallback((e) => {
    // if user clicks outside of erarrands container, preventDefault
    // fixes an issue when user accidentally taps on some message in chat
    // which triggers message options menu to appear while the side drawer is opened.
    if (childWrapperRef.current && !childWrapperRef.current.contains(e.target)) {
      e?.preventDefault()
    }
    e?.stopPropagation();
  }, []);

  // ClassNames
  const {
    errandBoxChildrenDivClassName,
    staticRopeWrapperClassName,
    ropeDrawerClassName,
    pullRopeWrapperClassName,
    errandBoxContainerDivClassName,
    pullRopeWrapperImgClassName
  } = React.useMemo(() => {
    const errandBoxChildrenDivClassName = `
    ${Styles.childWrapper} 
    ${Styles.pointerEventsAll} 
    ${state.isInvisible ? Styles.invisible : Styles.visible}
  `;
    const staticRopeWrapperClassName = `${state.pullRopeImgAnimated ? Styles.animated : ''} ${Styles.staticRopeWrapper} ${state.isPullingRope ? Styles.isPullingRope : ''}`;
    const ropeDrawerClassName = `${Styles.ropeDrawer} ${state.isDrawerOpen && props.isConnected ? Styles.isDrawerOpen : ''}`
    const pullRopeWrapperClassName = `${Styles.pullRopeWrapper} ${state.isPullingRope ? Styles.isPullingRope : ''}`;
    const errandBoxContainerDivClassName = `${Styles.errandsBox} ${state.isAnimated ? Styles.animated : ''}`;
    const pullRopeWrapperImgClassName = `${state.pullRopeImgAnimated ? Styles.animated : ''} ${Styles.nonSelectable}`;

    return {
      errandBoxChildrenDivClassName,
      staticRopeWrapperClassName,
      ropeDrawerClassName,
      pullRopeWrapperClassName,
      errandBoxContainerDivClassName,
      pullRopeWrapperImgClassName
    }
  }, [
    state.isInvisible,
    state.pullRopeImgAnimated,
    state.isPullingRope,
    state.isDrawerOpen,
    props.isConnected,
    state.isAnimated
  ]);


  // Styles
  const {
    rightPosStyle,
    errandBoxStyle,
    pullRopeStyle
  } = React.useMemo(() => {
    const rightPosStyle = {
      right: `${Math.abs(state.drawerPosition - getClosedDrawerPosValue())}px`
    };
    const errandBoxStyle = { left: `${state.drawerPosition}px` };
    const pullRopeStyle = { ...rightPosStyle };

    return {
      rightPosStyle,
      errandBoxStyle,
      pullRopeStyle
    }
  }, [state.drawerPosition, getClosedDrawerPosValue])

  const updateLocalState = useCallback((newState: Partial<State>) => {
    setState((prevState) => ({
      ...prevState,
      ...newState
    }))
  }, [])

  const setPullingState = useCallback(() => {
    updateLocalState({
      isInvisible: false,
      isPullingRope: true,
      isAnimated: false
    })
  }, [])

  const closeDrawerHandler = useCallback((e) => {
    if (state.isDrawerOpen === true) {
      closeDrawer(e);
    }
  }, [closeDrawer, state.isDrawerOpen]);

  const touchStartHandler = useCallback((e) => {
    startTouchRef.current.x = e.touches[0].clientX;
    startTouchRef.current.y = e.touches[0].clientY;
    if (checkStartClickOrTouchAllowed(e) === false) return;
    wasDraggedRef.current = false;
    setPullingState();
    processAutomaticController(e);
  }, [checkStartClickOrTouchAllowed, processAutomaticController]);

  const mouseDownHandler = useCallback((e) => {
    if (checkStartClickOrTouchAllowed(e) === false) return;
    setPullingState();
    setTimeout(() => { openDrawer(e) }, 150)
  }, [checkStartClickOrTouchAllowed, openDrawer]);

  const touchMoveHandler = useCallback((e) => {
    if (checkStartClickOrTouchAllowed(startTouchRef.current.y) === false) return;
    setPullingState();
    // mark as dragg started
    wasDraggedRef.current = true;
    const currTouch = e.touches[0].clientX;
    const dragDistance = startTouchRef.current.x - currTouch;
    const closedDrawerPosVal = getClosedDrawerPosValue();
    // calculate new pos
    const newPos = closedDrawerPosVal - dragDistance;
    let finalPos =
      newPos < 0 // if new pos is less than 0, beyond left edge point.
        ? 0
        : newPos > closedDrawerPosVal // if new pos is going beyond right edge point
          ? closedDrawerPosVal
          : newPos;
    // floor the val
    finalPos = Math.floor(finalPos);

    updateLocalState({
      pullRopeImgAnimated: false,
      drawerPosition: finalPos,
      isInvisible: false
    });

    processAutomaticController(e);
  }, [checkStartClickOrTouchAllowed, getClosedDrawerPosValue, processAutomaticController])

  const touchEndHandler = useCallback((e) => {
    processOpenCloseDrawer(e);
    // nullify the timeout for final action (automatic close/open when it just stopped);
    clearAutomaticController();
  }, [processOpenCloseDrawer]);

  const onClickHandler = useCallback((e) => openDrawer(e), [openDrawer]);

  return debouncedWindowDimensions.isDesktop || isWidget
    ? props.children
    : (
      <>
        {/* Whole Screen Rope Drawer Container Div */}
        <div
          className={ropeDrawerClassName}
        >
          {/* Errand Box Container Div */}
          <div
            style={errandBoxStyle}
            className={errandBoxContainerDivClassName}
            onTouchStart={closeDrawerHandler}
            onTouchEnd={stopPropagation}
            onClick={closeDrawerHandler}
            ref={rootContext.drawerRef}
          >
            <div className={errandBoxChildrenDivClassName} ref={childWrapperRef}>
              {props.children}
            </div>
          </div>
        </div>
        {/* Static Rope Wrapper Div (Rope small image in the middle of the right edge of the screen) */}
        <div
          className={staticRopeWrapperClassName}
          style={rightPosStyle}
        >
          <img
            src={RopeWithBump}
            draggable={false}
            className={Styles.nonSelectable}
            alt="rope representing menu button, pull to the left to open the menu"
          />
        </div>
        {/* Main Pull rope Wrapper Div (actual area which responds to events) */}
        <div
          className={pullRopeWrapperClassName}
          onTouchStart={touchStartHandler}
          onTouchMove={touchMoveHandler}
          onMouseDown={mouseDownHandler}
          onTouchEnd={touchEndHandler}
          onMouseUp={onClickHandler}
          onClick={onClickHandler}
          ref={pullAreaRef}
        >
          <img
            className={pullRopeWrapperImgClassName}
            src={RopeMobileDrawer}
            style={pullRopeStyle}
            draggable={false}
            alt="rope representing menu button, pull to the left to open the menu"
          />
        </div>
      </>
    );
};

export default React.memo(RopeDrawer);
