import React, { useRef, useState, useCallback, useEffect } from 'react';
import Styles from '@styles/TwinAvatarStyles.module.css';
import SkeletonStyles from '@styles/TwinAvatarSkeleton.module.css';

import { ValidatorFunctions } from '@common/Validators';
import { AvatarInstanceState } from '@common/AvatarType';

import { useAvatarContext } from '@contexts/avatar';
import axiosCall from '@services/axios';
import useAbortControllerV2 from '@common/hooks/useAbortControllerV2';
import { useMessageSingleContext } from '@contexts/message';
import { useMessageBodyObserverContext, VideoReadyState } from '@contexts/messageBodyObserver';

const MAX_FETCH_TIME_MS = 5000;

interface TWinAvatarInternalDataType {
  avatarFullyGrown: boolean;
  videoLoaded: boolean;
}

const LogPrefix = (): string => {
  const timestamp = ((Date.now() % 1000000) / 1000).toString().padStart(7, ' ');

  return `TwinAvatar ${timestamp}`;
};

enum TwinAvatarInternalEventType {
  AVATAR_GROWN = 'AVATAR_GROWN',
  VIDEO_LOADED = 'VIDEO_LOADED',
  VIDEO_FAILED_TO_LOAD = 'VIDEO_FAILED_TO_LOAD',
}

const TwinAvatar = () => {
  const { messageId, isLastMessage } = useMessageSingleContext();

  const {
    isAvatarLocked,
    avatarMutexState,
    claimAvatarOwnership,
    releaseAvatarOwnership,
    updateAvatarInstanceState,
    isAvatarInstanceState,
  } = useAvatarContext();

  const { videoLoadState, setVideoLoadState, isVideoLoadingFinished, setVideoDuration } =
    useMessageBodyObserverContext();

  const { getAbortSignal } = useAbortControllerV2();

  const [grown, setGrown] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const internalDataRef = useRef<TWinAvatarInternalDataType>({
    avatarFullyGrown: false,
    videoLoaded: false,
  });

  const handleVideoEnd = useCallback(() => {
    updateAvatarInstanceState(messageId, AvatarInstanceState.Finished);

    releaseAvatarOwnership(messageId);
  }, [messageId, releaseAvatarOwnership, updateAvatarInstanceState]);

  const restartVideo = useCallback(() => {
    updateAvatarInstanceState(messageId, AvatarInstanceState.Playing);

    const video = videoRef.current;

    if (!video) return;

    // Reset video to the beginning
    video.currentTime = 0;
    video.muted = false;

    video.play().catch(() => {
      console.error('Unable to play avatar video, Maybe due to browser restriction?');
      video.muted = true;

      video.play().catch(() => {
        handleVideoEnd();

        console.error("Even failed to play when it's muted");
      });
    });
  }, [messageId, handleVideoEnd, updateAvatarInstanceState]);

  /**
   * Handles a race condition between the avatar growth and video load events.
   * The video may load before or after the `AVATAR_GROWN` event.
   * To start the video, both events must occur.
   */
  const handleInternalEvent = useCallback(
    (event: TwinAvatarInternalEventType) => {
      const data = internalDataRef.current;

      switch (event) {
        case TwinAvatarInternalEventType.AVATAR_GROWN:
          data.avatarFullyGrown = true;
          break;
        case TwinAvatarInternalEventType.VIDEO_LOADED:
          data.videoLoaded = true;
          break;
        case TwinAvatarInternalEventType.VIDEO_FAILED_TO_LOAD:
          setVideoLoadState(VideoReadyState.FailedToLoad);
          handleVideoEnd();
          break;
        default:
          console.error(`Unhandled event: |${event}|`);
      }

      if (data.avatarFullyGrown && data.videoLoaded) {
        setVideoLoadState(VideoReadyState.Ready);
        restartVideo();
      }
    },
    [restartVideo, setVideoLoadState, handleVideoEnd]
  );

  const handleAvatarClick = useCallback(() => {
    if (claimAvatarOwnership(messageId)) {
      restartVideo();
    }
  }, [messageId, restartVideo, claimAvatarOwnership]);

  const handleTransitionEnd = useCallback(
    (event) => {
      if (event.propertyName === 'height') {
        if (grown) {
          handleInternalEvent(TwinAvatarInternalEventType.AVATAR_GROWN);
        } else {
          updateAvatarInstanceState(messageId, AvatarInstanceState.PendingUnmount);
        }
      }
    },
    [messageId, grown, updateAvatarInstanceState, handleInternalEvent]
  );

  const initVideoAvatar = useCallback(async () => {
    try {
      updateAvatarInstanceState(messageId, AvatarInstanceState.FetchingResource);

      // Create a config with timeout and abort signal
      const config = {
        ...getAbortSignal('initVideoAvatar'),
        timeout: MAX_FETCH_TIME_MS,
      };

      // fetch video URL from Core
      const res = await axiosCall(
        {
          url: `twin/avatarUrl/${messageId}`,
        },
        config
      );

      const storageLocation = res?.storageLocation;
      const duration = res?.duration;

      setVideoDuration(duration);

      if (ValidatorFunctions.isNotEmptyString(storageLocation)) {
        const video = videoRef.current;

        if (ValidatorFunctions.isNotUndefinedNorNull(video)) {
          updateAvatarInstanceState(messageId, AvatarInstanceState.LoadingVideo);

          // set video source to stream
          video.src = storageLocation;
          video.load();

          // add handler logic for start and finish
          video.onloadedmetadata = () => {
            handleInternalEvent(TwinAvatarInternalEventType.VIDEO_LOADED);
          };
        } else {
          throw new Error(`Video component ref not set yet`);
        }
      } else {
        throw new Error(`Unexpected storageLocation: |${storageLocation}|`);
      }
    } catch (err: any) {
      console.error(`TwinAvatar.tsx: initVideoAvatar() something went wrong! |${err.message}|`);

      setVideoDuration(0);

      handleInternalEvent(TwinAvatarInternalEventType.VIDEO_FAILED_TO_LOAD);
    }
  }, [messageId, getAbortSignal, updateAvatarInstanceState, handleInternalEvent, setVideoDuration]);

  useEffect(() => {
    console.debug(`${LogPrefix()} avatarMutexState: |${avatarMutexState}|`);

    const isAvatarNotActive = isAvatarInstanceState(messageId, undefined);
    const isAvatarFinished = isAvatarInstanceState(messageId, AvatarInstanceState.Finished);

    if (isLastMessage && isAvatarNotActive) {
      // Claim ownership and initialize video avatar for the first time
      if (claimAvatarOwnership(messageId)) {
        initVideoAvatar();
      }
    } else if (isAvatarFinished && isAvatarLocked) {
      // Collapse when next avatar claims ownership
      updateAvatarInstanceState(messageId, AvatarInstanceState.Collapsing);
      setGrown(false);
    } else if (isAvatarFinished && videoLoadState === VideoReadyState.FailedToLoad) {
      updateAvatarInstanceState(messageId, AvatarInstanceState.Collapsing);
      setGrown(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarMutexState, isAvatarLocked]);

  useEffect(() => {
    // start growing when component mounts
    setGrown(true);

    return () => {
      updateAvatarInstanceState(messageId, AvatarInstanceState.Unmounted);

      releaseAvatarOwnership(messageId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`${Styles.avatarContainer} ${grown ? Styles.growing : Styles.shrinking}`}
      onTransitionEnd={handleTransitionEnd}
      onClick={handleAvatarClick}
    >
      {<div className={`${SkeletonStyles.glowingCircle} ${isVideoLoadingFinished ? SkeletonStyles.hidden : ''}`} />}
      <video playsInline ref={videoRef} className={Styles.video} onEnded={handleVideoEnd}>
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default TwinAvatar;
