const MessageBodyStyles = {
  hiddenRender: {
    width: 'fit-content',
    opacity: 0,
  },
  instantShrink: {
    minWidth: 0,
    width: 0,
    opacity: 1,
    transition: 'width 550ms ease-in-out',
    position: 'relative',
  },
  expanding: {
    minWidth: 0,
    opacity: 1,
    transition: 'width 550ms ease-in-out',
    position: 'relative',
  },
  typing: {
    position: 'relative',
  },
  originalRestored: {
    width: 'fit-content',
    opacity: 1,
  },
};

export default MessageBodyStyles;
