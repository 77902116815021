import React, { useState, useRef, useEffect, MutableRefObject } from 'react';
import { useUserContext } from '@contexts/user';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Styles from '@styles/VideoPopout.module.css'

type TVideoPopoutProps = {
  videoFile: string; // This is the source of your video
  videoType?: string; // Optional: This is the video file type (ex. mp4)
  setAddMargin: (value: boolean) => void; // This adds spacing between your current message and the next
  showMessage: boolean; 
  setShowMessage: (value: boolean) => void;
  isExpandedVideo: boolean;
  setIsExpandedVideo: (value: boolean) => void;
  notRelevantToVideo: boolean; // Set this to false unless you want something excluded in render logic
  top: number; // This is the top margin you want your video circle to rest at
  leftOffset?: number; // Optional: Add a left offset if messageRef's width is not sufficient
  leftOverride?: number // Optional: Just set a left value rather than a margin
  messageRef: MutableRefObject<null | HTMLDivElement>; // This helps determine the left margin
  operatorOffset?: number; // Optional: This is the top margin you want your video circle to rest at for operator side
  poster?: string; //Optional: This allows you to set the default image of the video when it's not playing
}

const VideoPopout = ({
  videoFile, 
  videoType, 
  setAddMargin, 
  showMessage,
  setShowMessage, 
  isExpandedVideo, 
  setIsExpandedVideo, 
  notRelevantToVideo,
  top,
  leftOffset,
  leftOverride,
  messageRef,
  operatorOffset,
  poster
}: TVideoPopoutProps) => {

  const { isOperator } = useUserContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const [leftMargin, setLeftMargin] = useState(120 + leftOffset);
  const [showAnimatedIcon, setShowAnimatedIcon] = useState(isOperator);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = () => {
    setIsExpandedVideo(true);
    setShowMessage(false)
    setAddMargin(true);
    setIsPlaying(true);
    setShowAnimatedIcon(false);
  };

  const handleEnded = () => {
    setIsExpandedVideo(false);
    setShowMessage(true);
    setAddMargin(false);
    setIsPlaying(false);
    videoRef.current.currentTime = 0;
    setTimeout(() => {
      setShowAnimatedIcon(true);
    }, 500)
  };

  const handlePlayPause = () => {
    if (videoRef.current){
      if (isExpandedVideo) return;
      if (isPlaying){
        videoRef.current.pause();
        setShowAnimatedIcon(true);
      }else{
        videoRef.current.play()
        videoRef.current.muted = false;
        setShowAnimatedIcon(false);
      }
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (videoRef.current && !videoRef.current.contains(event.target as Node)) {
      handleEnded();
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    if (notRelevantToVideo) return; 
    if (isExpandedVideo) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isExpandedVideo]);

  useEffect(() => {
    const updateLeftMargin = () => {
      if (messageRef.current) {
        const width = messageRef.current.getBoundingClientRect().width;
        setLeftMargin(width - 88 + leftOffset);
      }
    };

    updateLeftMargin();

    window.addEventListener('resize', updateLeftMargin);

    return () => window.removeEventListener('resize', updateLeftMargin);
  }, [showMessage]);


  return(
    <div 
    style={{top: `${isOperator ? operatorOffset : 0}px`}}
    className={[Styles.videoBox,
      ...(isOperator ? [Styles.operatorVideo] : []),
      ...(isExpandedVideo ? [Styles.expandedBox] : [])
    ].join(' ')}
    onClick={handlePlayPause}
    >
      <video
        ref={videoRef}
        width={200}
        height={200}
        autoPlay={!isOperator}
        playsInline
        muted
        controls={isExpandedVideo}
        onPlay={handlePlay}
        onEnded={handleEnded}
        onError={handleEnded}
        style={{left: `${showMessage ? leftOverride ? leftOverride : leftMargin : 0}px`, top: `${showMessage ? top: 0}px`}}
        className={[Styles.video,
          ...(isExpandedVideo ? [Styles.expandedVideo] : []),
        ].join(' ')}
      >
        <source src={videoFile} type={videoType || undefined}/>
        <></>
      </video>
      
      {poster && showAnimatedIcon && !isExpandedVideo && !isPlaying &&
      <img 
        src={poster} 
        alt='Workflow animated icon' 
        className={Styles.animatedVideoIcon} 
        onClick={handlePlayPause}
      />}
      <div className={Styles.videoControls}
        style={{left: `${leftOverride ? leftOverride : leftMargin+3}px`, top: `${top+65}px`}}
      >
        {!isPlaying && showMessage &&
          <>
            <PlayArrowIcon className={Styles.videoButton} onClick={handlePlayPause} />
          </>
        }

        </div>
    </div>
  )

}

export default VideoPopout
