import React from 'react';
import './main.css';

/**
 * @description This component wraps whatever children you pass 
 * in its own wrapper that has a rainbow animation effect around 
 * the borders of the wrapper on all sides
 * @param props
 * @returns Wrapped Children
 */
const RainbowEffect = (props) => {
    return (
        <div style={
            props.style ?
            props.style :
            {
                borderRadius: '0.75rem',
                cursor: 'pointer'
            }}
            className="rainbowWrapper"
        >
                {props.children}
        </div>
    )
}


export { RainbowEffect };